<template>
  <ion-segment
    scrollable
    mode="md"
    class="tabs compact no-shadow bg-transparent customize-tabs"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <ion-segment-button
      v-for="(tab, index) of tabs"
      :key="index"
      class=""
      style="color: var(--ion-color-primary); max-width: 100%"
      :value="index"
    >
      <i v-if="startsWith(tab.icon, 'ti-')" class="d-flex icon" :class="tab.icon" />
      <inline-svg
        v-else-if="startsWith(tab.icon, '/icons')"
        width="24px"
        class="d-flex icon svg-icon"
        :src="tab.icon"
      />
      <ion-label v-else style="color: var(--ion-color-primary)"
        >{{ tab.name }}<span v-if="tab.value === 'submission'" class="text-primary"> ({{ count }}) </span></ion-label
      >
    </ion-segment-button>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  notIcon: {
    type: Boolean,
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  count: {
    type: Number,
    default: 0,
  },
  currTab: {
    type: String,
    deafult: '',
  },
});

const emits = defineEmits(['update:activeIndex', 'tab-changed']);
const tabs = toRef(props, 'tabs');

const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  const tab = tabs.value[tabIndex];

  emits('update:activeIndex', tabIndex);
  emits('tab-changed', tab);
};
</script>

<style scoped lang="sass">
.icon
  font-size: 16px
  font-weight: normal
.svg-icon
  background-color: transparent !important
  fill: #41476c !important

.customize-tabs
  margin-bottom: 0px !important
  margin-top: 0px !important


.shrink
  flex: none !important

.segment-button-checked::part(native)
  background: var(--ion-color-primary)


.segment-button::part(native)
  background: #41476c !important

ion-segment-button::part(native)
  background: transparent !important

.segment-button-checked
  ::v-deep
    background:  #41476c !important
    border-bottom-left-radius: 0px !important
    border-bottom-right-radius: 0px !important
    color: white !important
.segment-button-checked
  *
    color: white !important
    background:  #41476c !important
    fill: white !important
</style>
