<template>
  <div class="quizzes w-100" :class="{ disabled: !userHasCharacters }">
    <div class="d-flex quizzes-list w-100">
      <scroller
        ref="scrollerRef"
        :scroll-size="300"
        :threshold="20"
        :no-gradient-background="true"
        @resize="recalcScroll"
      >
        <div class="quiz-card d-flex align-items-center">
          <ion-button class="see-all-text">
            <router-link v-if="seeAllLink && !isEmpty(quizzes)" :to="seeAllLink" style="color: white">
              See all <span v-if="quizzesCount">{{ quizzesCount }}</span> quizzes</router-link
            >
          </ion-button>
          <div v-for="(quiz, index) in quizzes" :key="index">
            <quizzes-card :quiz="quiz" @skip="emits('skip')" />
          </div>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script lang="ts" setup>
import QuizzesCard from './QuizzesCard.vue';
import { authStore } from '@/shared/pinia-store/auth';
const props = defineProps({
  quizzes: { type: Array, default: () => [] },
  seeAllLink: { type: Object, default: () => {} },
  quizzesCount: { type: String, default: '' },
  quizNotifsData: { type: Array, default: () => [] },
});
const quizzes = toRef(props, 'quizzes');
const seeAllLink = toRef(props, 'seeAllLink');
const scrollerRef: Ref = ref(null);
const { userHasCharacters } = authStore();
const emits = defineEmits(['fetch', 'skip']);
const recalcScroll = () => {
  setTimeout(() => {
    try {
      if (scrollerRef.value) (scrollerRef.value as any).calculateScroll();
    } catch (e) {}
  }, 150);
};
watch(
  quizzes,
  () => {
    recalcScroll();
  },
  { deep: true }
);
</script>
<style lang="sass" scoped>
.title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px

.quizzes
  overflow-x: auto
.quizzes-list
  overflow-x: auto
.quizzes-list::-webkit-scrollbar
  display: none
.quizzes-list
  -ms-overflow-style: none
  scrollbar-width: none

.quiz-card
  gap: 5px
  margin-top: 1rem
  margin-bottom: 0.5rem
.see-all-text
  font-size: 16px
  height: 180px
  white-space: normal
  text-align: left
  width: 104px
  max-width: 104px
  min-width: 104px
  border-radius:12px
  background: #7050B7 !important
  --background: #7050B7 !important
  color: white
  text-decoration: underline
</style>
