<template>
  <div>
    <div v-if="loader && !feed.length" class="row mx-1">
      <div class="col-md-6 col-6 px-1" v-for="(item, index) in 2" :key="index">
        <ion-skeleton-text animated class="skeleton" />
      </div>
    </div>
    <div v-else-if="renderLoader" class="row mx-1">
      <div class="col-md-6 col-6 px-1" v-for="(item, index) in feed.length" :key="index">
        <ion-skeleton-text animated class="skeleton" />
      </div>
    </div>
    <div v-else>
      <div v-if="feed && feed.length" ref="layoutEl">
        <client-only>
          <div v-masonry :key="forcererenderKey" transition-duration="0" class="masonry-container">
            <div v-masonry-tile class="item col-md-3 col-sm-4 px-1 mb-2" :key="index" v-for="(item, index) in feed">
              <post-card
                v-if="get(item, 'type') === 'post'"
                :post="get(item, 'entity')"
                @updated="emits('updated')"
                @delete="deleteCurrGiftBox"
                :indent-reaction-right="index % 2 === 1"
                :reacted-posts="get(reaction.blab, `${get(item, 'entity.id')}`)"
                :containerHeight="get(item, 'height')"
                :blab-replies-count="get(blabrepliesCount, `${get(item, 'entity.id')}.blab_replies_count`) || 0"
                :lazyUserReaction="lazyUserReaction"
              />

              <character-card
                v-else-if="get(item, 'type') === 'character'"
                :character="get(item, 'entity')"
                @updated="emits('updated')"
                @delete="deleteCurrGiftBox"
                :reacted-chars="get(reaction.character, `${get(item, 'entity.id')}`)"
                :char-comments-count="get(commentsCount, `${get(item, 'entity.id')}.comments_count`) || 0"
                :lazyUserReaction="lazyUserReaction"
              />

              <story-world-card
                v-else-if="get(item, 'type') === 'story'"
                :world="get(item, 'entity')"
                @updated="emits('updated')"
                @delete="deleteCurrGiftBox"
                :vertical="!isMobSmallScreen"
                :reacted-story="get(reaction.world, `${get(item, 'entity.id')}`)"
                :lazyUserReaction="lazyUserReaction"
              />

              <social-space-card
                v-else-if="get(item, 'type') === 'socialspace'"
                :social-space="get(item, 'entity')"
                :for-feed="!isMobSmallScreen"
                @updated="emits('updated')"
                @delete="deleteCurrGiftBox"
                :lazyUserReaction="lazyUserReaction"
                :reacted-spaces="get(reaction.world, `${get(item, 'entity.id')}`)"
                :member-count="get(spaceMemberCount, `${get(item, 'entity.id')}.member_count`) || 0"
                :room-count="get(spaceroomcount, `${get(item, 'entity.id')}.room_count`) || 0"
              />

              <follow-card
                v-else-if="get(item, 'type')?.endsWith('follow')"
                :feed="item"
                :char-comments-count="get(commentsCount, `${get(item, 'entity.id')}.comments_count`) || 0"
                :member-count="get(spaceMemberCount, `${get(item, 'entity.id')}.member_count`) || 0"
                :room-count="get(spaceroomcount, `${get(item, 'entity.id')}.room_count`) || 0"
                :reacted-chars="get(reaction.character, `${get(item, 'entity.id')}`)"
                :reacted-spaces="get(reaction.world, `${get(item, 'entity.id')}`)"
                :reacted-world="get(reaction.world, `${get(item, 'entity.id')}`)"
                :reacted-blab="get(reaction.blab, `${get(item, 'entity.id')}`)"
                :lazyUserReaction="lazyUserReaction"
                @updated="emits('updated')"
                @delete="deleteCurrGiftBox"
              />

              <ChatroomCard
                v-else-if="get(item, 'type') === 'chatroom'"
                class="chatroom-card my-0"
                :chatroom="get(item, 'entity')"
                :is-feed="true"
                :member-count="get(chtrmStatusAndCount, `${get(item, 'entity.id')}.member-count`) || 0"
                :status="get(chtrmStatusAndCount, `${get(item, 'entity.id')}.status`) || false"
                :lastmsg="get(chtrmStatusAndCount, `${get(item, 'entity.id')}.last_msg_time`) || null"
                :unreadchat="get(unreadCounts, `${get(item, 'entity.id')}.unread_count`) || 0"
              />

              <div v-else-if="get(item, 'type') === 'ads' && !isMobile">
                <ClientOnly>
                  <Ad :index="index" />
                </ClientOnly>
              </div>
            </div>
          </div>
        </client-only>
        <client-only>
          <div class="d-flex justify-content-center my-3">
            <infinite-loading v-if="totalFeed !== false" @infinite="infiniteLoadHandler">
              <template #complete>
                <span></span>
              </template>
              <template #spinner>
                <div class="d-flex justify-content-center">
                  <ChLoading size="md" class="spinner" />
                </div>
              </template>
            </infinite-loading>
          </div>
        </client-only>
      </div>
      <div v-else class="no-data">No posts here yet</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InfiniteLoading from 'v3-infinite-loading';
import PostCard from '@/shared/components/Posts/PostCard.vue';
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';
import SocialSpaceCard from '@/shared/components/storage/SocialSpaceCard.vue';
import FollowCard from '@/shared/components/storage/FollowCard.vue';
import ChatroomCard from '@/shared/components/storage/ChatroomCard.vue';
import { authStore } from '@/shared/pinia-store/auth';
import Ad from './Ad.vue';
import { useMobile } from '~/apps/mobile/composables/useMobile';

const layoutEl = ref(null);

const props = defineProps({
  feed: {
    type: Array<any>,
    default: () => [],
    required: true,
  },
  totalFeed: {
    type: Boolean,
    default: false,
  },
  loader: {
    type: Boolean,
    default: false,
  },

  repliesCounts: {
    type: Number,
    default: () => 0,
  },
  lazyUserReaction: {
    type: Boolean,
    default: false,
  },
  spaceroomcount: {
    type: Object,
    default: {},
  },
  reaction: {
    type: Object,
    default: {},
  },
  blabrepliesCount: {
    type: Object,
    default: {},
  },
  unreadCounts: {
    type: Object,
    default: {},
  },
  commentsCount: {
    type: Object,
    default: {},
  },
  spaceMemberCount: {
    type: Object,
    default: {},
  },
  chtrmStatusAndCount: {
    type: Object,
    default: {},
  },
});

const totalFeed = toRef(props, 'totalFeed');
const emits = defineEmits(['loadMore', 'updated', 'delete']);
const feed = toRef(props, 'feed');
const renderLoader = ref(false);
const { creationsVisible } = authStore();
const forcererenderKey = ref(0);

const { isMobile } = useMobile();

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value < 380;
});

const deleteCurrGiftBox = (id: any) => {
  emits('delete', id);
};

const infiniteLoadHandler = ($state: any) => {
  if (totalFeed.value) {
    emits('loadMore');
  } else {
    $state.complete();
  }
};

const updateMasonryKey = async () => {
  forcererenderKey.value = forcererenderKey.value += 1;
  setTimeout(() => {
    renderLoader.value = false;
  }, 300);
};

watch(windowWidth, () => {
  renderLoader.value = true;
  updateMasonryKey();
});

watch(creationsVisible, () => {
  renderLoader.value = true;
  updateMasonryKey();
});

onMounted(() => {
  updateMasonryKey();
});
</script>

<style lang="sass" scoped>
.item
  max-width:33.333333%
  @media(max-width:1370px) and (min-width:1185px)
    max-width:50%
  @media(max-width:825px) and (min-width:540px)
    max-width:50% !important
  @media(max-width:540px)
    max-width:100% !important
.masonry-container
  margin: 0 auto

.skeleton
  height: 320px
  border-radius: 12px
</style>
