<template>
  <ion-modal class="modal-big" :is-open="isOpen" mode="md" :show-backdrop="true" @didDismiss="dismissModal">
    <div class="inner-content d-flex flex-column py-3">
      <div
        class="d-flex justify-content-center align-items-center text-center"
        :class="{ 'flex-column': isSelectedChar }"
      >
        <div class="title text-primary">
          {{ isSelectedChar ? 'Character Selected!' : 'Choose which character people will decorate!' }}
        </div>
        <div v-if="isSelectedChar" class="text-primary">
          <small
            >People will start decorating this <strong>{{ get(selectedItem, 'info.name') }}</strong> soon!</small
          >
        </div>
      </div>

      <div v-if="!isSelectedChar" class="px-3 py-2">
        <!-- <div v-if="loading" class="d-flex justify-content-center"><ChLoading size="sm" /></div> -->
        <div>
          <SearchInput
            v-model="text"
            :blur-on-esc="true"
            :select-on-focus="false"
            :shortcut-listener-enabled="true"
            :clear-icon="false"
            :hide-shortcut-icon-on-blur="false"
            :clear-on-esc="true"
            placeholder="Search your Characters here"
            @input="onSearch"
          />
          <div v-if="characters.length" class="char-dec-content">
            <div class="container py-3">
              <div
                class="row justify-content-center row-cols-3 row-cols-sm-3 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 no-gutters"
              >
                <div
                  v-for="(character, index) of characters"
                  :key="index"
                  class="col py-1"
                  :class="isMobSmallScreen ? 'col-padding' : 'px-1'"
                >
                  <div
                    class="card"
                    :style="{ backgroundColor: cardBackground(index, character) }"
                    :class="{
                      active: selectedIndex === index && !character.enable,
                      'no-border': selectedIndex !== index,
                      'clickable-item': !character.enable,
                    }"
                    @click="setActive(index, character)"
                  >
                    <img
                      class="card-img w-100"
                      style="object-fit: cover"
                      :class="{ 'disable-img': character.enable }"
                      :src="get(character, 'info.cropProfilePicture', '/empty.png') || '/empty.png'"
                    />
                    <div class="text-center text-clr truncate-title" :class="{ 'disable-img': character.enable }">
                      {{ get(character, 'info.name') }}
                    </div>
                    <ion-badge v-if="character.enable">submitted</ion-badge>
                  </div>
                </div>
              </div>
              <div v-if="chars.length && chars.length < totalChars" class="text-center pt-3">
                <ChLoading size="sm" v-if="isCharLoading" class="spinner" />
                <p v-else class="clickable-item" @click="loadMoreChar">Load More</p>
              </div>
            </div>
          </div>
          <div v-else class="no-data">No characters found</div>

          <div class="mt-2 text">**Characters marked Mature cannot be used in this event.</div>
          <div class="d-flex mt-2 char-btn">
            <ion-button class="text-black not-sub-btn" color="transparent" @click="onAction">
              <div class="d-flex flex-column">
                <div>Don't Submit</div>
                <div style="text-transform: none"><small>I don't want people to decorate my character</small></div>
              </div>
            </ion-button>
            <ion-button
              class="w-100 sub-btn"
              :disabled="selectedIndex === '' || selectedItem.enable"
              :class="{ 'sub-bg': selectedIndex === '' || selectedItem.enable }"
              @click="onSubmit"
              >Submit</ion-button
            >
          </div>
        </div>
      </div>
      <div v-else class="px-3">
        <div class="d-flex flex-column justify-content-center align-items-center text-center mt-3">
          <img loading="lazy" :src="get(selectedItem, 'info.cropProfilePicture', '/empty.png')" class="char-img" />
          <div class="py-2">Keep an eye out for people's decorations.</div>
          <div class="mb-2">Now it's your turn to decorate.</div>
        </div>

        <ion-button class="w-100 next-btn" @click="onAction">
          <div class="d-flex">
            next
            <strong> <ion-icon class="icon mx-2" :icon="arrowForwardOutline" /></strong>
          </div>
        </ion-button>
      </div>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';

import { arrowForwardOutline } from 'ionicons/icons';


import { toast } from '@/shared/native';

import { submitCharacterPoll } from '@/shared/actions/events';
import { SiteEventTask } from '@/shared/types/static-types';

const text = ref('');
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
  isSearch: {
    type: Boolean,
    default: false,
  },
  task: {
    type: Object as PropType<SiteEventTask>,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  chars: {
    type: Array,
    default: () => [],
  },
  totalChars: {
    type: Number,
    default: 0,
  },
});

const emits = defineEmits(['close', 'action', 'search', 'loadMore']);
const windowHeight = ref('0px');
const selectedIndex: any = ref('');
const selectedItem: any = ref({});
const isSelectedChar = ref(false);

const task = toRef(props, 'task');
const isOpen = toRef(props, 'isOpen');
const chars = toRef(props, 'chars');

const userCharacters = toRef(props, 'chars');

const searchchars: any = ref([]);
const router = useRouter();

const isCharLoading = ref(false);

const characters = computed(() => {
  return !isEmpty(searchchars.value) ? searchchars.value : userCharacters.value;
});
const dismissModal = () => {
  isSelectedChar.value = false;
  (window as any).$crisp?.push(['do', 'chat:show']);
  const document = useDocument();
  const el = document.value?.querySelector('.crisp-client') as any;
  if (el) el.style.display = 'block';
  emits('close');
};
const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 600;
});
const onSearch = () => {
  searchTextChanged();
};
const setActive = async (index: any, character: any) => {
  if (isEmpty(get(character, 'info.cropProfilePicture'))) {
    await toast.show('Character with no profile image cannot be selected.', 'nonative', 'danger');
  } else {
    selectedItem.value = character;
    selectedIndex.value = index;
  }
};

const searchTextChanged = debounce(() => {
  emits('search', text.value);

  // searchchars.value = await fetchMyOcCharacters({ search: text.value });
}, 500);

const cardBackground = (index: any, character: any) => {
  const clrArray = ['#e0eef8', '#ffe1e8', '#d9f4f6', '#f0eafa'];
  return character.enable ? 'rgb(210 210 210)' : clrArray[index % clrArray.length];
};
const onAction = () => {
  emits('action');
};

const loadMoreChar = () => {
  isCharLoading.value = true;
  emits('loadMore');
};

const onSubmit = async () => {
  // if (!selectedIndex.value) return;
  // isSelectedChar.value = true;

  const payload = {
    character: selectedItem.value.id,
    eventtask: task.value.id,
    current_iteration: 0,
  };
  await submitCharacterPoll(payload);
  // await getCharactersEventPollCounts()
  isSelectedChar.value = true;
};

const handleResize = () => {
  windowHeight.value = `${window.innerHeight}px`;
};

watch(isOpen, () => {
  if (isOpen.value) {
    (window as any).$crisp?.push(['do', 'chat:hide']);
    const document = useDocument();
    const el = document.value?.querySelector('.crisp-client') as any;
    if (el) el.style.display = 'none';
    router.replace({ name: 'home', query: { event: 'decoration-fest' } });
  } else {
    router.replace({ name: 'home' });
  }
});

watch(chars, () => {
  if (chars.value.length) {
    isCharLoading.value = false;
  }
});

onMounted(() => {
  windowHeight.value = `${window.innerHeight}px`;
  window.addEventListener('resize', handleResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
</script>

<style lang="sass" scoped>
.disable-img
  opacity: 0.55
.dark .text-clr
  color: black !important
.next-btn
  --border-radius: 10px
.char-img
  // width: 370px
  min-height: 100px
  max-height: 40vh
  border-radius: 10px
  // @media(min-width:700px) and (max-height:560px)
  //   height: calc(100vh - 217px)
.char-btn
  @media(max-width:600px)
    flex-direction: column-reverse
.col-padding
  padding-left: 3px
  padding-right: 3px
.card-img
  height: 125px
  @media(max-width:500px)
    height: 100px
.sub-bg
  --background: #a5a5a5
  color: black
.active
  pointer-events: none
  border: 4px solid #ae38e5

.no-border
  border: none
.card
  border-radius: 7px
  overflow: hidden
  height: 100%
  position: relative

.not-sub-btn
  border: 2px solid #c3c3c3
  border-radius: 10px
  height: 37px
  width: calc(100% - 200px)
  @media(max-width:600px)
    width: 100%
.sub-btn
  --border-radius: 10px

.char-dec-content
  max-height: calc(v-bind(windowHeight) - 223px) !important
  overflow: auto
  @media(max-width: 576px)
    max-height: 46vh !important
.title
  font-weight: bold
  font-size: 20px
.text
  font-size: 14px
.modal-big
  --width: 770px
  --height: auto
  @media(max-width: 576px)
    --width: 100%

.inner-content
  background: var(--ion-background-color, #fff)
  max-height: 95vh
  @media(max-width: 576px)
    max-height: 90vh

.truncate-title
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

ion-badge
  position: absolute
  z-index: 1
  top: 5px
  right: 5px
</style>
