<template>
  <div>
    <ion-card
      class="compliment-card m-0 clickable-item-hov"
      @click="actionClicked(true)"
      :class="{ 'disabled-card': get(character.user, 'id') === user.id }"
    >
      <div class="h-100 w-100 position-relative">
        <div class="compliment-badge" :style="character.complimented_by_me ? 'background:#6ADF32' : ''">
          <inline-svg :src="getComplimentBombSvg" :class="{ 'marked-svg': character.complimented_by_me }" />
        </div>
        <img
          loading="lazy"
          v-character-card-image
          class="compliment-img"
          :src="resizeUpload(get(character.info, 'cropProfilePicture'), '190h')"
          :class="{ 'p-1': !get(character.info, 'cropProfilePicture') }"
        />
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div>{{ truncateEasy(get(character.info, 'name'), 12) }}</div>
          <div>@{{ truncateEasy(get(character.user, 'username'), 12) }}</div>
        </div>
      </div>
    </ion-card>
    <compliment-bombs-modal
      :is-open="isOpenFirstComplimentBombModal"
      @close="isOpenFirstComplimentBombModal = false"
      @done="closeAndOpenCharacterComplimentModal"
    />
    <character-compliment-bomb-modal
      :is-open="isOpenCharacterComplimentBomb"
      :character="character"
      :cbDailyLimit="cbDailyLimit"
      :complimentCharacters="complimentCharacters"
      :isCbLoader="isCbLoader"
      @fetch="fetchCbLimit"
      @open="closeAndOpenCBListModal"
      @mission="closeandOpenMissionSuccessfulModal"
      @close="isOpenCharacterComplimentBomb = false"
    />

    <character-compliment-mission-modal
      :is-open="isOpenCBListModal"
      :inventory="inventory"
      :count="complimentCount"
      :limit="cbDailyLimit"
      :isMissionCompleted="isMissionCompleted"
      @close="closeCbListModal"
    />
  </div>
</template>
<script lang="ts" setup>
import ComplimentBombsModal from '@/shared/modals/ComplimentBombsModal.vue';
import CharacterComplimentBombModal from '@/shared/modals/CharacterComplimentBombModal.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { updateUserdata } from '@/shared/actions/users';
import CharacterComplimentMissionModal from '@/shared/modals/CharacterComplimentMissionSuccessfulModal.vue';
import { resizeUpload } from '@/shared/utils/upload';
import { truncateEasy } from '@/shared/utils/string';

const isOpenFirstComplimentBombModal = ref(false);
const isOpenCBListModal = ref(false);
const isOpenCharacterComplimentBomb = ref(false);
const props = defineProps({
  inventory: {
    type: Object,
    default: () => {},
  },
  character: {
    type: Object,
    default: () => {},
  },
  cbDailyLimit: {
    type: Object,
    default: () => {},
  },
  complimentCharacters: {
    type: Array,
    default: [],
  },
  isCbLoader: {
    type: Boolean,
    default: false,
  },
});

const {
  user,
  updateLoggedInUserData,
  updateTempAvailableCompliment,
  updateComplimentCount,
  updateTotalComplimentedCount,
} = authStore();
const inventory = toRef(props, 'inventory');
const character = toRef(props, 'character');
const cbDailyLimit = toRef(props, 'cbDailyLimit');
const emits = defineEmits(['fetch']);
const isMissionCompleted = ref(false);
const complimentCount = ref(null);

const openCbListModal = (val: any) => {
  isOpenCBListModal.value = true;
};

const getComplimentBombSvg = computed(() => {
  return character.value.complimented_by_me ? '/checkmark-circle-outline.svg' : '/compBomb.svg ';
});
const actionClicked = (val: any) => {
  user.value.bomb_first_time_use
    ? (isOpenFirstComplimentBombModal.value = true)
    : character.value.complimented_by_me ||
      cbDailyLimit.value.done_in_time >= cbDailyLimit.value.default_limit ||
      cbDailyLimit.value.available_compliments === 0
    ? openCbListModal(val)
    : (isOpenCharacterComplimentBomb.value = true);
};

const updateCBFirstTimeUse = async () => {
  if (user.value.bomb_first_time_use) {
    updateLoggedInUserData({ bomb_first_time_use: false });
    await updateUserdata(user.value.id, { bomb_first_time_use: false });
  }
};

const closeCbListModal = () => {
  fetchCbLimit();
  complimentCount.value = null;
  isOpenCBListModal.value = false;
  updateTempAvailableCompliment(null);
  updateComplimentCount(1);
  updateTotalComplimentedCount(null);
};

const fetchCbLimit = () => {
  emits('fetch');
};

const closeAndOpenCharacterComplimentModal = async () => {
  updateCBFirstTimeUse();
  isOpenFirstComplimentBombModal.value = false;
  isOpenCharacterComplimentBomb.value = true;
};

const closeAndOpenCbMsModal = () => {
  isOpenCharacterComplimentBomb.value = false;
  isOpenCBListModal.value = true;
};

const closeAndOpenCBListModal = (data: any) => {
  complimentCount.value = data;
  closeAndOpenCbMsModal();
};

const closeandOpenMissionSuccessfulModal = () => {
  isMissionCompleted.value = true;
  closeAndOpenCbMsModal();
};
</script>

<style lang="sass" scoped>
.marked-svg
  color: white
  width: 20px
  height: 20px
.username
  position: absolute
  bottom: 4px
  left: 8px
  font-weight: bold
  font-size: 15px
.rest-info
  border-radius: 12px
  width: 100%
  height: 100%
  padding: 6px
  background: linear-gradient(180deg, rgba(247,6,207,0) 0%, rgba(0,0,0,0.3) 74%, #170624CF 100%)
.disabled-card
  opacity: 0.5
  pointer-events: none
.compliment-badge
  position: absolute
  border: 4px solid #7050b7
  background-color: #45b6f5
  border-radius: 10px
  width: 34px
  top: -2px
  left: -2px
  height: 34px
  display: flex
  justify-content: center
  align-items: center
.char-cb-badge
  position: absolute
  width: 90%
  border-radius: 6px
  background: #3dc2ff
.target-text
  font-weight: bold
.target-icon
  display: flex
  align-items: center
  font-weight: bold

.compliment-card
  overflow: unset !important
  background: #7050B7 !important
  color: white
  border-radius: 17px
  border: 7px solid #7050B7 !important
  width: 135px
  min-width: 145px !important
  max-width: 145px !important
  min-height: 180px
  max-height: 180px
.compliment-img
  width: 100% !important
  height: 126px !important
  object-fit: cover
  border-radius: 16px
  @media(max-width:419px)
    height: 115px !important
</style>
