<template>
  <ion-modal class="modal" :is-open="isOpen" :backdrop-dismiss="true" :show-backdrop="true">
    <div class="inner-content d-flex flex-column justify-content-center align-items-center">
      <ChLoading size="sm" style="width: 25px; height: 25px" />
      <span class="mt-2"> Loading... </span>
    </div>
  </ion-modal>
</template>

<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
});
const isOpen = toRef(props, 'isOpen');
</script>

<style lang="sass" scoped>
.modal
  --width: 100px
  --height: 100px

.inner-content
  background: var(--ion-background-color, #fff)
  height: inherit
  overflow: hidden
  overflow-x: hidden
</style>
