<template>
  <div class="mt-3" v-if="topContributors.length">
    <div class="mt-4 mb-2">
      <div style="font-size: 18px; font-weight: 600">Top Contributors</div>
    </div>

    <div class="d-flex" style="overflow-x: scroll; max-width: 1200px">
      <div
        :style="isMobSmallScreen ? 'min-width: 30vw' : 'width: 8vw'"
        v-for="(user, index) in topContributors.slice(0, isMobSmallScreen ? topContributors.length : 5)"
        :key="user.id"
      >
        <div style="position: relative" class="d-flex flex-column align-items-center mb-2 mr-1">
          <img class="user-image mb-1" :src="resizedProfilePicture(user)" alt="" />
          <router-link class="truncate" :to="`/profile/${user.username}`"> @{{ user.username }} </router-link>
          <div class="medal">
            <inline-svg :src="`/icons/${icons[index]}`" class="medal-icon" />
          </div>
        </div>
      </div>
    </div>

    <div class="hr">
      <hr />
    </div>
  </div>
</template>

<script setup lang="ts">

import { getTopContributors } from '@/shared/actions/follow';
import { resizeUpload } from '@/shared/utils/upload';

const props = defineProps({
  tag: {
    type: String,
    default: () => '',
  },
});

const resizedProfilePicture = (user: any) => {
  return user.profile_picture_url ? resizeUpload(user.profile_picture_url, '250x250') : '/empty.png';
};
const tag = toRef(props, 'tag');
const icons = ref(['top1.svg', 'top2.svg', 'top3.svg']);

watch(tag, () => {
  fetchTopContributors();
});
const topContributors: any = ref([]);

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 576;
});

const fetchTopContributors = async () => {
  const res = await getTopContributors({ tag: tag.value });
  topContributors.value = res;
};

onMounted(() => {
  if (!isEmpty(tag.value)) fetchTopContributors();
});
</script>

<style lang="sass" scoped>

.truncate
  width: 70px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.create-btn
  --border-radius: 15px
  width: 120px

.user-image
  border-radius: 12px
  width: 120px
  height: 120px
  object-fit: cover

.medal-icon
  height: 35px

.medal
  position: absolute
  top: 10px
  left: 2px

.hr
  margin-top: 18px
  margin-bottom: 25px

.ios .hr
  margin-top: 100px
  margin-bottom: -70px
</style>
