import { defineStore, storeToRefs } from 'pinia';
import { createReactionOnComments, deleteReactionOnComments } from '@/shared/actions/reactions';
import { UserReaction, ReactionCommentCounts } from '@/shared/types/static-types';

const isUserReactedSameReaction = (reaction: string, comment: any): boolean => {
  const { user_reaction } = comment;
  return !!(user_reaction && user_reaction.id && user_reaction.reaction === reaction);
};

const sendReactionToBackend = async (reaction: string, comment: any) => {
  const target = 'comment';
  const { user_reaction, id: commentId = '' } = comment;

  // delete
  if (isUserReactedSameReaction(reaction, comment)) {
    return await deleteReactionOnComments({ reactionId: user_reaction?.id || '' });
  }

  // post
  const data = await createReactionOnComments({ objectId: commentId, reaction, target });
  return data;
};

const updateReactionsDataFoComment = (reaction: string, comment: any, newReaction: UserReaction) => {
  const commentData = comment;
  commentData.reaction_counts = commentData.reaction_counts || { total_count: 0 };
  const totalCount = commentData.reaction_counts.total_count;
  let userReaction = commentData.user_reaction || ({} as UserReaction);
  const prevUserReaction = commentData.user_reaction as UserReaction;

  // editing counts if un-reacting or editing reaction
  if (commentData.user_reaction as UserReaction) {
    commentData.reaction_counts[prevUserReaction.reaction as keyof ReactionCommentCounts]--;
    if (newReaction.reaction in commentData.reaction_counts) {
      commentData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts]++;
    } else {
      // ignore; newReaction.reaction will never be "ordering" (which requires type string[])
      //  @ts-ignore
      commentData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts] = 1;
    }
  }
  // if reacting to story for the first time: editing counts & user_reaction
  if (!userReaction.id) {
    commentData.reaction_counts!.total_count = totalCount + 1;
    if (!commentData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts]) {
      //  @ts-ignore
      commentData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts] = 1;
    } else {
      //  @ts-ignore
      commentData.reaction_counts[newReaction.reaction as keyof ReactionCommentCounts] += 1;
    }
    userReaction = newReaction;
  }

  // if un-reacting
  if (isUserReactedSameReaction(reaction, commentData) && !newReaction) {
    commentData.reaction_counts!.total_count = totalCount > 0 ? totalCount - 1 : 0;
    commentData.user_reaction = undefined;
  } else {
    commentData.user_reaction = {
      ...userReaction,
      reaction,
    };
  }

  const out = {
    user_reaction: commentData.user_reaction,
    reaction_counts: commentData.reaction_counts,
  };
  return out;
};

export interface IComplimentVoteStore {
  complimentVote: null;
  isLoading: boolean;
}

const useComplimentVote = defineStore('complimentvote', {
  state: (): IComplimentVoteStore => ({
    complimentVote: null,
    isLoading: false,
  }),
  actions: {
    async react(reaction: string, comment: any) {
      const newReaction = await sendReactionToBackend(reaction, comment);
      const updatedReactionsData = updateReactionsDataFoComment(reaction, comment, newReaction);

      return { newReaction, updatedReactionsData };
    },
  },
  getters: {},
});

export const complimentVoteStore = () => {
  const store = useComplimentVote();
  return {
    ...store,
    ...storeToRefs(store),
  };
};
