<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-title class="title">Compliment Bombs Hub </ion-title>
        </div>
        <ion-buttons slot="end">
          <ion-button class="close-button" @click="dismissModal()">
            <i class="ti-close" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content">
      <div class="px-3 py-3 text-black">
        <div class="sub-title">MY COMPLIMENT BOMBS</div>
        <div class="mt-1">Manage the Compliment Bombs in your Inventory</div>
        <div class="user-compliment px-3 py-3 my-2" v-for="(inv, index) in cbInventories" :key="index">
          <div class="compliment-bomb-section">
            <div class="compliment-bomb-content position-relative">
              <div
                class="position-absolute d-flex info-icon align-items-center justify-content-center clickable-item-hov"
                :class="{ 'info-cons-icon': inv.value === inventories.consumable }"
                @click="openInfoDetails(inv.value)"
              >
                <i class="ti-info-alt" />
              </div>
              <inline-svg class="comp-bomb-icon" :src="inv.svg" />
              <ion-badge class="compliment-bomb-badge mx-1">x{{ inv.amount }}</ion-badge>
            </div>

            <div class="compliment-text-content text-black">
              <compliment-bomb-character-selection
                v-if="inv.value === inventories.consumable"
                @updated="emits('updated')"
                :inventory="inventory"
                :class="{ 'hide-cursor': isInventoryAmount }"
              >
                <ion-button class="use-compliment-btn" :disabled="isInventoryAmount">Use</ion-button>
              </compliment-bomb-character-selection>

              <div
                class="d-flex align-items-center mx-auto"
                :class="{ 'set-direction': inv.value === inventories.gift }"
              >
                <div
                  class="clickable-item-hov"
                  :class="{ 'mt-1': inv.value === inventories.gift }"
                  @click="openGetMoreModal"
                >
                  Get More
                </div>

                <div
                  class="mx-2"
                  style="font-size: 22px"
                  v-if="isGiftAndConsAmount && inv.value === inventories.consumable"
                >
                  |
                </div>
                <GiftComplimentBomb
                  :inventory="inventory"
                  :giftInventory="giftInventory"
                  :class="{ 'hide-cursor': inv.value === inventories.gift && get(giftInventory, 'amount') <= 0 }"
                  @updated="fetchInventoryDetails"
                >
                  <div class="clickable-item-hov" v-if="inv.value === inventories.consumable && isGiftAndConsAmount">
                    <i class="ti-gift gift-icon" v-if="inv.value === inventories.consumable" /> Gift!
                  </div>

                  <div v-else-if="inv.value === inventories.gift">
                    <ion-button class="use-compliment-btn" :disabled="get(giftInventory, 'amount') <= 0">
                      <div class="d-flex align-items-center">
                        <i class="ti-gift gift-icon mr-1" />

                        <div class="mt-1">Gift!</div>
                      </div>
                    </ion-button>
                  </div>
                </GiftComplimentBomb>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-title">My Characters Who Got Bombed</div>
        <ChLoading size="md" class="mx-auto" v-if="currUserloading" />
        <div class="py-2" v-else>
          <div v-if="!isEmpty(otherUsersBombedChars)">
            <compliment-bomb-vertical-card
              v-for="(character, index) in otherUsersBombedChars"
              :key="index"
              :character="character"
              :title="title(character)"
              :description="'You should star seeing compliments trickle in within 48 hours of bombing.'"
              @close="dismissModal"
            />
          </div>
          <div v-else class="no-data">No Character Bombed yet!</div>
        </div>
        <div class="sub-title">Characters I've Bombed</div>
        <ChLoading size="md" class="mx-auto" v-if="loading" />
        <div v-else>
          <Grid v-if="!isEmpty(myBombedChars)" :scrollSm="false" :sm="3" :lg="5" :md="4">
            <CharacterCard
              v-for="character in myBombedChars"
              :key="`char-${character.id}`"
              class="grid-item"
              :character="character"
              :compliment-bomb-hub="true"
              @close="dismissModal"
            />
          </Grid>
          <div v-else class="no-data">No Character Bombed yet!</div>
        </div>
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar">
        <div class="d-flex justify-content-center text-center align-items-center">
          <ion-button @click="openGetMoreModal">Get More</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>

    <compliment-bomb-use-and-purchase-modal
      :is-open="isOpenPurchaseBombModal"
      :inventory="inventory"
      :purchase-more="true"
      @close="closePurchaseModal"
      @purchase="closeModal"
    />

    <gift-compliment-bomb-modal
      :is-open="isOpenGiftComplimentBombModal"
      :gift-inventory="giftInventory"
      :inventory="inventory"
      modal-title="Info"
      :show-footer="false"
      :cbInfoValue="selectedValue"
      @close="isOpenGiftComplimentBombModal = false"
    />
  </ion-modal>
</template>

<script lang="ts" setup>
import ComplimentBombVerticalCard from '@/shared/components/ComplimentBombVerticalCard.vue';
import ComplimentBombCharacterSelection from '@/shared/pages/compliment-bombs/components/ComplimentBombCharacterSelection.vue';
import ComplimentBombUseAndPurchaseModal from './ComplimentBombUseAndPurchaseModal.vue';
import Grid from '@/shared/components/storage/Grid.vue';
import GiftComplimentBombModal from '@/shared/modals/GiftComplimentBombModal.vue';
import CharacterCard from '@/shared/components/EventCharacterCard.vue';
import { getUserComplimentBombedChars } from '@/shared/actions/complimentBomb';
import constants from '@/shared/statics/constants';
import GiftComplimentBomb from '@/shared/pages/compliment-bombs/components/GiftComplimentBomb.vue';

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  inventory: {
    type: Object,
    default: {},
  },
  giftInventory: {
    type: Object,
    default: {},
  },
});
const emits = defineEmits(['close', 'view', 'updated', 'gifted']);
const isOpen = toRef(props, 'isOpen');
const giftInventory = toRef(props, 'giftInventory');
const isOpenPurchaseBombModal = ref(false);
const selectedValue = ref('');
const isOpenGiftComplimentBombModal = ref(false);
const inventory = toRef(props, 'inventory');
const charCbType = constants.charComplimentbombType;
const myBombedChars: any = ref([]);
const otherUsersBombedChars: any = ref([]);
const loading = ref(false);
const currUserloading = ref(false);
const tempInvType = ref('');

const inventories = constants.inventories;
const cbInventories = computed(() => {
  return [
    { svg: '/compliment-bomb.svg', amount: get(inventory.value, 'amount', 0), value: 'consumable' },
    { svg: '/wrapped-cbomb.svg', amount: get(giftInventory.value, 'amount', 0), value: 'gift' },
  ];
});
watch(isOpen, async () => {
  if (isOpen.value) {
    await fetchCurrUserCb();
    fetchOtherUsersCb();
  }
});

const fetchCbChars = async (bombType: any) => {
  const resp = await getUserComplimentBombedChars({ bomb_type: bombType });
  return resp;
};

const fetchInventoryDetails = (val: any) => {
  tempInvType.value = val;
  emits('gifted', tempInvType.value);
};

const fetchCurrUserCb = async () => {
  currUserloading.value = true;
  const resp = await fetchCbChars(charCbType.bombedByMe);
  myBombedChars.value = resp;
  currUserloading.value = false;
};

const fetchOtherUsersCb = async () => {
  loading.value = true;
  const resp = await fetchCbChars(charCbType.bombedByOthers);
  otherUsersBombedChars.value = resp;
  loading.value = false;
};

const openInfoDetails = async (val: any) => {
  selectedValue.value = val;
  isOpenGiftComplimentBombModal.value = true;
};
const dismissModal = () => {
  emits('close');
};

const isInventoryAmount = computed(() => {
  return isEmpty(inventory.value) || get(inventory.value, 'amount', 0) === 0;
});

const isGiftAndConsAmount = computed(() => {
  if (get(inventory.value, 'amount', 0) <= 0 && get(giftInventory.value, 'amount', 0) <= 0) return false;
  else return true;
});

const openGetMoreModal = (val: any) => {
  isOpenPurchaseBombModal.value = true;
};

const closePurchaseModal = () => {
  isOpenPurchaseBombModal.value = false;
};

const closeModal = () => {
  closePurchaseModal();
  dismissModal();
};

const title = (character: any) => {
  return `
  <span  class="clickable-item-hov">${get(
    character,
    'info.name',
    'unknown'
  )}</span> <span class="text-black" style="pointer-events: none">is being currently bombed</span>`;
};
</script>
<style lang="sass" scoped>
.info-cons-icon
  top: 8px !important
  left: 6px !important
.info-icon
  width: 20px
  height: 20px
  color: #FFF
  top: 6px
  left: 2px
  background: #0A0928E5
  font-size: 12px
  border-radius: 12px
.set-direction
  flex-direction: column-reverse
.hide-cursor
  pointer-events: none !important
.gift-icon
  font-weight: bold
.compliment-bomb-content
  align-items: center
  display: flex
  @media(max-width:299px)
    margin-left: auto !important
    margin-right: auto !important
.compliment-text-content
  font-weight: bold
  color: #214163
  display: flex
  flex-wrap: wrap
  flex-direction: column
  margin-left: auto !important
  @media(max-width:299px)
    width: 100%

.grid-item
  @media(min-width:1200px)
    width: calc(20% - 5px) !important
    min-width: calc(20% - 5px) !important
.compliment-bomb-section
  align-items: center
  display: flex
  @media(max-width:299px)
    flex-wrap: wrap
.use-compliment-btn
  width: 180px
  max-width: 100%
  min-width: 100%
  font-size: 18px
  font-weight: bold
  @media(max-width:500px)
    width:140px

.compliment-bomb-badge
  background: white
  font-weight: bold
  display: flex
  height: 23px
  width: 40px
  align-items: center
  justify-content: center
  min-width: 40px
  max-width: 40px
  color: var(--ion-color-primary)
  border-radius: 9px
  font-size: 14px
.comp-bomb-icon
  width: 71px
  height: 71px
  @media(max-width:340px)
    width: 50px
    height: 50px
.dark .toolbar
  --background: #17074c !important
.modal-inner-content
  background: var(--ion-background-color, #e6e6e6)
  max-height: 70vh
  max-height: 70dvh
  overflow-x: hidden
  overflow-y: auto
.modal-big
  --width: 490px
  --height: auto
  --border-radius: 10px
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.sub-title
  font-size: 18px
  font-weight: bold
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  padding-left: 55px
  padding-right: 0px
.user-compliment
  border-radius: 10px
  border: 2px solid #cfcaca
</style>
