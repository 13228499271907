<template>
  <div>
    <div class="oc-sub-card">
      <div v-if="get(ocDecorations, 'length')" class="mt-3">
        <div class="row no-gutters">
          <div
            v-for="decoration in ocDecorations"
            :key="`char-${get(decoration, 'id')}`"
            class="col-12 col-sm-6 col-md-4 d-flex align-items-stretch px-1"
            style="margin-top: -13px"
          >
            <div class="oc-card mx-0 mb-3" style="border: 3px solid #e3e3e3">
              <div class="one-oc px-4 py-2">
                <div class="d-flex justify-content-center">
                  <img loading="lazy" v-image :src="get(decoration, 'image.image')" />
                </div>

                <div class="d-flex flex-column justify-content-center align-items-center text-center py-2">
                  <div class="d-flex">
                    <ion-icon class="icon mx-1" :icon="personCircleOutline" />
                    <span
                      >{{ isMobSmallScreen ? '' : 'Character' }} by
                      <router-link
                        :class="{
                          'text-color-username':
                            usernameColor(get(decoration, 'receiver')) &&
                            !usernameColor(get(decoration, 'receiver')).startsWith('#'),
                          'bold-username': usernameColor(get(decoration, 'receiver')),
                        }"
                        :style="
                          !usernameColor(get(decoration, 'receiver'))
                            ? ''
                            : usernameColor(get(decoration, 'receiver')).startsWith('#')
                            ? `color: ${usernameColor(get(decoration, 'receiver'))} !important`
                            : `background-image: ${usernameColor(get(decoration, 'receiver'))} !important`
                        "
                        :to="`/profile/${get(decoration, 'receiver.username')}`"
                        target="_blank"
                      >
                        @{{ get(decoration, 'receiver.username') }}
                      </router-link>
                    </span>
                  </div>
                  <div class="d-flex">
                    <ion-icon class="icon-brush mx-1" :icon="colorPaletteOutline" />
                    <span class="dec-text"
                      >{{ isMobSmallScreen ? '' : 'Decoration' }} by
                      <router-link
                        :class="{
                          'text-color-username':
                            usernameColor(get(decoration, 'image.source_user')) &&
                            !usernameColor(get(decoration, 'image.source_user')).startsWith('#'),
                          'bold-username': usernameColor(get(decoration, 'image.source_user')),
                        }"
                        :style="
                          !usernameColor(get(decoration, 'image.source_user'))
                            ? ''
                            : usernameColor(get(decoration, 'image.source_user')).startsWith('#')
                            ? `color: ${usernameColor(get(decoration, 'image.source_user'))} !important`
                            : `background-image: ${usernameColor(get(decoration, 'image.source_user'))} !important`
                        "
                        :to="`/profile/${get(decoration, 'image.source_user.username')}`"
                        target="_blank"
                      >
                        @{{ get(decoration, 'image.source_user.username') }}
                      </router-link>
                    </span>
                  </div>
                </div>
                <div v-if="!isSub" class="d-flex justify-content-center align-items-center text-center">
                  <div class="actions d-flex">
                    <Reaction
                      type="visual"
                      :reactions="get(decoration, 'image.reaction_counts')"
                      :user-reaction="get(decoration, 'image.user_reaction')"
                      @changed="(reaction) => reactionChanged(reaction.key, get(decoration, 'image'))"
                      @click.stop
                    >
                      <ion-button color="transparent" class="inline-button icon-button">
                        <div
                          class="d-flex align-items-center justify-content-center text-primary"
                          :class="{
                            'text-secondary':
                              !!get(decoration, 'image.user_reaction') &&
                              get(decoration, 'image.user_reaction.reaction'),
                          }"
                        >
                          <i class="ti-thumb-up mr-2" />
                          <span class="reaction-count">{{
                            get(decoration, 'image.reaction_counts.total_count') || 0
                          }}</span>
                        </div>
                      </ion-button>
                    </Reaction>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="ocDecorations.length && ocDecorations.length < totalChars" class="text-center">
          <ChLoading size="sm" v-if="isCharLoading" class="spinner" />
          <p v-else class="clickable-item" @click="loadMoreChar">Load More</p>
        </div>
      </div>
      <div v-else class="no-data py-4" style="margin-top: 92px">
        <div v-if="isSub">
          If you don't see your submission here, please wait while Hubert delivers your amazing decoration to the owner.
          You'll be able to see it once Hubert delivers it
        </div>
        <div v-else>No decoration featured for the community yet.</div>
      </div>
    </div>

    <div
      class="d-flex text-white px-2 py-2 mt-auto text-center justify-content-center"
      style="background: rgb(33 65 99); border-radius: 10px"
    >
      <vue-countdown v-slot="{ days }" :time="formatFromNowInMilliseconds(task.ends_at)">
        <div v-if="!days">
          <div>OC Decoration Fest ends today! Thanks for participating!!!</div>
        </div>
        <div v-else-if="days < 2">
          <div>OC Decoration Fest ends tomorrow! Thanks for participating!!!</div>
        </div>

        <div v-else-if="!isMobSmallScreen" class="d-flex">
          <span class="mx-1">Come back {{ eventTime.hours || eventTime.minutes ? 'in:' : '' }} </span>
          <div
            v-if="eventTime.hours || eventTime.minutes"
            style="
              background: white;
              color: rgb(33 65 99);
              border-radius: 6px;
              height: 25px;
              margin-top: -3px;
              align-items: center;
            "
            class="px-2 d-flex"
          >
            <div style="padding-top: 3px"><ion-icon class="icon" :icon="timeOutline" /></div>
            <div class="mx-1">
              <span v-if="eventTime.hours"> {{ eventTime.hours }}h </span
              ><span v-if="eventTime.minutes">{{ eventTime.minutes }}m </span>
            </div>
          </div>
          <div v-else>later</div>
          <span class="pl-1">to Submit and Decorate more!</span>
        </div>

        <div v-else class="d-flex flex-column" style="font-size: 14px">
          <div class="d-flex align-items-center mb-1">
            <div class="mx-1">Come back {{ eventTime.hours || eventTime.minutes ? 'in:' : '' }}</div>
            <div
              v-if="eventTime.hours || eventTime.minutes"
              style="background: white; color: rgb(33 65 99); border-radius: 6px; height: 25px; align-items: center"
              class="px-2 d-flex"
            >
              <div style="padding-top: 3px"><ion-icon class="icon" :icon="timeOutline" /></div>
              <div class="mx-1">
                <span v-if="eventTime.hours"> {{ eventTime.hours }}h </span
                ><span v-if="eventTime.minutes">{{ eventTime.minutes }}m </span>
              </div>
            </div>
            <div v-else>later</div>
          </div>
          <div class="pl-1">to Submit and Decorate more!</div>
        </div>
      </vue-countdown>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { personCircleOutline, colorPaletteOutline, timeOutline } from 'ionicons/icons';
import { formatFromNowInMilliseconds } from '@/shared/utils/dateTime';
import store from '@/shared/store';
import Reaction from '@/shared/components/Reaction/index.vue';

const props = defineProps({
  ocDecorations: {
    type: Array,
    default: () => [],
  },
  isOpen: {
    type: Boolean,
  },
  task: {
    type: Object,
    default: () => {},
  },
  isSub: {
    type: Boolean,
    default: false,
  },
  eventTime: {
    type: Object,
    default: () => {},
  },
  totalChars: {
    type: Number,
    default: 0,
  },
});

const isCharLoading = ref(false);

const task = toRef(props, 'task');
const ocDecorations = toRef(props, 'ocDecorations');
const emits = defineEmits(['onReaction', 'loadMore']);

const loadMoreChar = () => {
  isCharLoading.value = true;
  emits('loadMore');
};

const isMobSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 800;
});

watch(ocDecorations, () => {
  if (ocDecorations.value.length) {
    isCharLoading.value = false;
  }
});

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const reactionChanged = async (reaction: string, image: any) => {
  // this.$emit('onReaction', { reactResp: resp, image });

  const resp = await store.dispatch('ImagesModule/reactImage', {
    reaction,
    image,
  });

  emits('onReaction', { reactResp: resp, image });
};
</script>
<style lang="sass" scoped>
.oc-sub-card
  height: 55vh !important
  overflow-x: hidden
  overflow-y: auto
.icon-brush
  position: relative
  left: 2px
  font-size: 18px
  @media(max-width:800px)
    left: 0px

.dec-text
  position: relative
  left: 5px
  @media(max-width:800px)
    left: 3px
.icon
  font-size: 20px

.one-oc
  img
    height: 225px
    width: 100vh !important
    object-fit: contain
    border-radius: 10px
    position: relative
    left: 1px
.oc-card
  height: calc(100% - 1.5rem) !important
</style>
