<template>
  <ion-card
    class="clickable-item-hov p-0 m-0 raffle-feed-card"
    :style="raffle.is_new ? 'border: 3px solid red !important' : ''"
  >
    <router-link :to="{ name: 'raffle-details', params: { slug: raffle.slug } }">
      <div class="d-flex flex-column">
        <div>
          <div id="buttons" class="badge-feed-container">
            <div class="badge-row flex-wrap">
              <ion-badge class="badge" style="min-width: 133px">
                <div class="description m-auto">
                  {{
                    raffle.category === 'other'
                      ? truncateBadgeText(stripHtmlTags(raffle.other_category))
                      : categoriesByVal[raffle.category].name
                  }}
                </div>
              </ion-badge>
            </div>
          </div>
          <img loading="lazy" v-image :src="raffle.visuals[0]" class="raffle-img" />
        </div>
        <div class="d-flex flex-column p-1">
          <div
            class="name d-flex text-black justify-content-center align-items-center text-center clickable-item h-100"
          >
            {{ truncateTitleText(stripHtmlTags(raffle.title)) }}
          </div>

          <div class="d-flex text-black align-items-center flex-column mt-auto">
            <div
              class="text-primary d-flex align-items-center text-center"
              style="font-size: 12px; font-weight: bold"
              @click.stop
            >
              <router-link
                class="d-flex"
                :class="{
                  'text-color-username':
                    usernameColor(get(raffle, 'user')) && !usernameColor(get(raffle, 'user')).startsWith('#'),
                  'bold-username': usernameColor(get(raffle, 'user')),
                }"
                :style="
                  !usernameColor(get(raffle, 'user'))
                    ? ''
                    : usernameColor(get(raffle, 'user')).startsWith('#')
                    ? `color: ${usernameColor(get(raffle, 'user'))} !important`
                    : `background-image: ${usernameColor(get(raffle, 'user'))} !important`
                "
                :to="{ name: 'profile', params: { username: get(raffle, 'user.username') } }"
              >
                <span class="mr-1"> @{{ get(raffle, 'user.username') }}</span>
                <ProfileBadge :badges="get(raffle, 'user.customize_profile.badges') || []" :show-small-badge="true" />
              </router-link>
            </div>

            <ion-button v-if="get(raffle, 'status') !== 'ended'" class="end-btn">
              <i class="ti-timer mx-1" />
              Ends in

              <div class="mx-1">
                <vue-countdown
                  v-if="raffle?.start_date"
                  v-slot="{ days, hours, minutes }"
                  :time="countdownTime(raffle)"
                  class="d-flex"
                  style="gap: 3px"
                >
                  <div v-if="days">{{ days }}d</div>

                  <div v-if="hours">{{ leadingZero(hours) }}h</div>

                  <div v-if="!days">{{ leadingZero(minutes) }}m</div>
                </vue-countdown>
              </div>
            </ion-button>
          </div>
        </div>
      </div>
    </router-link>
  </ion-card>
</template>

<script lang="ts" setup>
import { stripHtmlTags } from '@/shared/utils/string';
import constants from '@/shared/statics/constants';
import { leadingZero } from '@/shared/utils/number';
import ProfileBadge from '@/shared/pages/profile/components/ProfileBadge.vue';

const props = defineProps({
  raffle: { type: Object, default: {} },
});

const categoriesByVal = keyBy(constants.raffleCategories, 'value');
const raffle: any = toRef(props, 'raffle');

const usernameColor = (user: any) => {
  return user?.customize_profile?.username_color || '';
};

const getBadgeColor = computed(() => {
  return categoriesByVal[raffle.value.category].color;
});

const truncateBadgeText = (value: string, length = 30) => {
  return truncate(value, { length, separator: /,? +/ });
};
const truncateTitleText = (value: string, length = 17) => {
  return truncate(value, { length, separator: /,? +/ });
};

const countdownTime = (value: any) => {
  const durationInMilliseconds = value.duration * 24 * 60 * 60 * 1000;
  const startTime = new Date(get(value, 'start_date')).getTime();
  const endTime = startTime + durationInMilliseconds;
  return endTime - Date.now();
};
</script>

<style lang="sass" scoped>
.raffle-img
  width: 100%
  height: 80px
  object-fit: cover
  border-radius: 10px
  padding: 2px

.description
  font-size: 10px


.badge
  display: flex
  align-items: center
  height: 20px
  justify-content: center
  width: auto
  max-width: 100%
  min-width: 68px
  border-radius: 20px
  background: #09183c
  color: v-bind(getBadgeColor)
  border: 2px solid v-bind(getBadgeColor)


.name
  font-size: 13px
  font-weight: bold
  color: #000
  cursor: pointer
  word-break: break-word
  margin: auto 0.25rem
  .name:hover
    opacity: 0.7

.badge-row
  display: flex
  width: 100%
  gap: 2px

.badge-feed-container
  display: flex
  flex-direction: column
  align-items: center
  max-width: 400px
  margin: 4px 4px 0px 4px
.end-btn
  height: 20px
  --border-radius: 20px
  text-transform: none
  font-size: 11px !important

.title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px

.raffle-feed-card
  overflow: unset !important
  min-width: 145px !important
  max-width: 145px !important
  min-height: 180px
  max-height: 180px
  background: transparent
  border: 2.5px solid #7050B7 !important
  border-radius: 12px
</style>
