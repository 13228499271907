<template>
  <div
    v-if="currentAnnouncementIsPopulated"
    :class="{ 'has-link': currentAnnouncement?.link?.title }"
    class="position-relative announcement d-flex align-items-center background gradient d-flex align-items-center justify-space-between text-center"
  >
    <header class="anmtc flex-grow-1">
      <div class="text-left" v-html="currentAnnouncement.text" />
      <div class="text-left" v-if="userTime">(In your timezone: {{ userTime }})</div>
    </header>
    <ion-button v-if="currentAnnouncement?.link?.title" class="mx-2 my-0 link" color="dark" @click="handleLink">
      {{ currentAnnouncement.link.title }}
    </ion-button>
    <ion-button class="dismiss d-flex ml-auto lighten-hover pointer" @click="dismiss">
      <i class="ti-close" />
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import moment from 'moment';
import { announcementStore } from '@/shared/pinia-store/announcement';

const { currentAnnouncement, dismiss } = announcementStore();

const handleLink = () => {
  if (!currentAnnouncement.value?.link?.href) return;
  window.open(currentAnnouncement.value.link.href, '_blank');
};

const currentAnnouncementIsPopulated = computed(() => {
  return currentAnnouncement.value?.text || currentAnnouncement.value?.title || currentAnnouncement.value?.link;
});

const userTime = computed(() => {
  if (!currentAnnouncement.value) return;
  const { datetime } = currentAnnouncement.value;

  if (datetime) {
    return moment(currentAnnouncement.value.datetime).local().format('MMMM Do, h:mm a');
  }

  return '';
});
</script>

<style scoped lang="sass">
@keyframes animatedGradient
  to
    background-position: 200% center
.background
  background: linear-gradient(90deg, rgb(247, 6, 207) 0%, rgb(174, 56, 229) 50%, rgb(247, 6, 207) 100%)
.gradient
  background-size: 200% auto !important
  animation: animatedTextGradient 5s linear infinite
.announcement
  max-width: 100%
  width: 100%
  color: #FFF
  pointer-events: all
  user-select: none
  font-size: 14px
  font-weight: bold
  border-radius: 12px
  padding: 10px
  .link
    --ion-color-base: rgba(0,0,0,0.5) !important
    border: 2px solid rgba(0,0,0,0.2) !important
    border-radius: 12px
    font-size: 14px
    --ion-color-contrast: #FFF !important
  .text-left
    text-align: left
  .dismiss
    font-size: 13px
    width: 25px
    min-width: 25px
    --background: transparent
    --border-radius: 20px
    height: 25px
    --padding-start: 0
    --padding-end: 0
  @media(max-width: 500px)
    &.has-link
      padding: 10px 10px 30px 10px
    .link
      position: absolute
      --padding-start: 10px
      --padding-end: 10px
      height: 25px
      right: 0
      bottom: 8px
    .dismiss
      align-self: flex-start
</style>
