<template>
  <div class="topics mb-4 d-flex flex-column" :class="isCustomizing ? 'topics-stylize p-3' : 'pt-3'">
    <div :key="forcererenderScrollerKey" v-show="!isCustomizing" class="compact no-shadow bg-transparent ion-segment">
      <scroller
        ref="scrollerRef"
        :key="forcererenderScrollerKey"
        class="scroller"
        :scroll-size="300"
        :threshold="20"
        :no-gradient-background="true"
        :with-background="true"
      >
        <div class="d-flex">
          <ion-segment-button
            v-for="(topic, index) in pinnedTopics"
            :key="`${topic.name}-${index}`"
            class="shrink tab"
            :class="pressed === topic.name ? 'text-primary selected-tab' : ''"
            :value="topic.name"
            @click="onClick(topic.name, index)"
          >
            <ion-label>{{ topic.name }}</ion-label>
          </ion-segment-button>
        </div>
      </scroller>
    </div>
    <div class="closed" :class="{ opened: isCustomizing }">
      <div class="" :style="isMobSmallScreen ? 'max-height: 200px; overflow-y: auto' : ''">
        <Sortable
          :key="forcererenderKey"
          class="row my-2 mx-1"
          :class="{ 'justify-content-center ': isMobSmallScreen }"
          item-key="name"
          tag="div"
          :list="customizeTopics"
          :options="sortableOptions"
          @end="handleReorder"
        >
          <template #item="{ element, index }">
            <div
              :id="`i_${element.name}_${index}`"
              class="item item-small-screen d-flex align-items-center p-2"
              :class="{
                'mx-2': isMobSmallScreen,
                active: selectedIndex === index,
                'not-active': selectedIndex !== index,
              }"
            >
              <div v-if="!staticKeys.includes(element.name)" style="width: 10%">
                <inline-svg
                  @click.prevent="() => (staticKeys.includes(element.name) ? null : removeTopic(element, index))"
                  style="stroke: #ae38e5; fill: #ae38e5"
                  src="/icons/pin.svg"
                  class="trend-icon clickable-item-hov"
                />
              </div>
              <div
                class="text-center truncate mx-2 clickable-item-hov"
                style="width: 80%"
                @click="onClick(element.name, index)"
              >
                {{ element.name }}
              </div>
            </div>
          </template>
        </Sortable>
      </div>

      <div v-if="isCustomizing" class="searchtopics mx-2 p-3">
        <div class="search-input d-flex align-items-center desktop-nav-bar">
          <inline-svg height="12px" class="position-absolute icon ml-1" src="/icons/search-bar-mag.svg" />
          <ion-input v-model="text" class="c-input" @input="searchTextChanged" />
        </div>
        <div class="scroll-area">
          <div
            v-for="(topic, index) in searchRes"
            :key="`${topic.name}-${index}`"
            class="search-item btn-hover d-flex align-items-center clickable-item p-2"
            @click="addTopic(topic, index)"
          >
            <div style="width: 3%">
              <inline-svg
                style="stroke: #181c3c"
                src="/icons/pin.svg"
                class="trend-icon pin-icon-stroke"
                :class="{ 'ml-auto': !topic.content_count }"
              />
            </div>
            <div class="truncate mx-2" style="width: 77%">
              {{ topic.name }}
            </div>
            <div class="d-flex align-items-center" style="width: 24%; white-space: nowrap">
              <inline-svg
                v-if="topic.is_trending"
                style="width: 20%; stroke: #ae38e5"
                src="/icons/fire-woodfire.svg"
                class="trend-icon"
                :class="{ 'ml-auto': !topic.content_count }"
              />
              <span v-if="topic.content_count" style="width: 80%" class="ml-auto">
                ({{ topic.content_count >= 1000 ? '1k+' : topic.content_count }} new posts)
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center mx-2 my-3">
        <div class="heading mr-1">
          <inline-svg src="/icons/fire-woodfire.svg" class="trend-icon trend-icon-stroke" />
        </div>
        <strong class="popular-topic"> Popular Topics </strong>
      </div>
      <div class="row my-2 mx-1">
        <div
          v-for="(topic, index) in allTrendingTopics?.slice(0, 10)"
          :key="`${topic.name}-${index}`"
          class="item btn-hover d-flex align-items-center clickable-item p-2"
          @click="addTopic(topic, index)"
        >
          <div style="width: 10%">
            <inline-svg
              style="stroke: #181c3c"
              src="/icons/pin.svg"
              class="trend-icon"
              :class="{ 'ml-auto': !topic.content_count }"
            />
          </div>
          <div class="text-center truncate mx-2" style="width: 80%">
            {{ topic.name }}
          </div>
          <div style="width: 10%">
            <inline-svg src="/icons/fire-woodfire.svg" class="trend-icon" style="stroke: #ae38e5" />
          </div>
        </div>
      </div>
    </div>
    <div>
      <ion-button class="customize-btn mt-2" @click="toggleCustomize">
        <inline-svg class="mr-1" src="/customize.svg" />

        <span>{{ isCustomizing ? 'Close' : 'Customize Tabs' }}</span>
      </ion-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isTouchScreen } from '@/shared/utils/ui';
import {
  getPinnedTopics,
  getTrendingTopics,
  pinTopic,
  unPinTopic,
  reOrderTopics,
  searchTopics,
} from '@/shared/actions/follow';

const sortableOptions = {
  group: {
    name: 'imgmove',
    pull: true,
    put: false,
  },
  draggable: '.inner-i-d',
  fallbackOnBody: true,
  animation: 50,
  delay: isTouchScreen() === false ? 0 : 200,
  touchStartThreshold: 5,
  fallbackTolerance: 5,
  chosenClass: 'held',
  swapThreshold: 1,
};

const staticKeys = ['All', 'Following'];
const pinnedTopics: any = ref([]);
const isCustomizing = ref(false);
const trendingTopics: any = ref([]);
const searchRes: any = ref([]);
const staticTopics: any = ref([{ name: 'All' }, { name: 'Following' }]);
const customizeTopics: any = ref([...staticTopics.value]);
const forcererenderKey = ref(0);
const forcererenderScrollerKey = ref(0);

const text = ref('');
const emits = defineEmits(['onFilter']);
const pressed = ref('All');
const selectedIndex = ref(0);

const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value <= 576;
});

const allTrendingTopics = computed(() => {
  return trendingTopics.value.filter(
    (topic: any) => !customizeTopics.value.map((item: any) => item.name).includes(topic.name)
  );
});

const toggleCustomize = () => {
  fetchPinnedTopics();
  isCustomizing.value = !isCustomizing.value;
};

const addTopic = async (topic: any, index: any) => {
  searchRes.value.splice(index, 1);
  await pinTopic({ tag: topic.name });
  customizeTopics.value.push(topic);
};

const removeTopic = async (topic: any, index: any) => {
  customizeTopics.value.splice(index, 1);
  nextTick(() => {
    forcererenderKey.value++;
  });
  await unPinTopic({ tag: topic.name });
  onClick('All', 0);
};

const onClick = (tag: any, index: any) => {
  pressed.value = tag;
  selectedIndex.value = index;
  emits('onFilter', tag);
};

const onSearch = async () => {
  const res = await searchTopics({ search: text.value });
  searchRes.value = res.results.filter((item: any) => !item.is_following);
};

const searchTextChanged = debounce(() => {
  onSearch();
}, 500);

const fetchPinnedTopics = async () => {
  const res = await getPinnedTopics();
  pinnedTopics.value = [];
  pinnedTopics.value = pinnedTopics.value.concat(staticTopics.value).concat(res);
  customizeTopics.value = [...staticTopics.value, ...res];

  forcererenderScrollerKey.value = forcererenderScrollerKey.value += 1;
};

const fetchTrendingTopics = async () => {
  const res = await getTrendingTopics();
  trendingTopics.value = res.results;
};

const handleReorder = async (e: any) => {
  customizeTopics.value.splice(e.newIndex, 0, customizeTopics.value.splice(e.oldIndex, 1)[0]);
  const tags = customizeTopics.value.map((topic: any) => topic.name).slice(2, customizeTopics.value.length);
  await reOrderTopics({ order: tags });
};

onMounted(() => {
  fetchTrendingTopics();
  fetchPinnedTopics();
});
</script>

<style lang="sass" scoped>
.active
  border: 1px solid var(--ion-color-primary) !important
  color: var(--ion-color-primary) !important
.not-active
  border: 1px solid grey
  color: grey
.dark .pin-icon-stroke
  stroke: white !important
.popular-topic
  font-size: 20px
  color: grey
.dark .popular-topic
  color: white

.topics-stylize
  border: 2px solid #808080
  border-radius: 14px

.searchtopics
  border: 1px solid #7A7E9B
  border-radius: 10px
.ion-segment
  margin-inline: 10px

.shrink
  flex: none !important

.tab
  font-size: 0.9rem
  text-transform: none
  letter-spacing: 0
  font-weight: bold
  color: #214163
  height: 37px
  min-height: 37px
  max-height: 37px
  z-index: 0 !important
  border-radius: 9px
.dark .tab
  color: #fff !important
ios .tab
  font-size: 0.8rem
  text-transform: none
  letter-spacing: 0
  font-weight: bold
  height: 40px
  min-height: 40px
  max-height: 40px
  z-index: 0 !important

.selected-tab
  background: #7050b7 !important
  color: white !important


.scroller
  pointer-events: all
  ::v-deep .right-scroller
    z-index: 0 !important
    width: 45px !important

  ::v-deep .left-scroller
    z-index: 0 !important
    width: 45px !important

  ::v-deep .button
    background: grey !important
    border-radius: 8px !important
    margin-right: 0px !important
    .icon-dark
      color: white !important
  ::v-deep .chevron-button
    height: 35px !important

ion-button
  --background: transparent

.item
  margin-inline: 1px
  margin-bottom: 5px
  border: 1px solid grey
  border-radius: 5px
  color: grey
  width: 145px
  height: 35px

.item-small-screen
  @media(max-width:373px)
    width: calc(100% - 177px )
  @media(max-width:351px)
    width: calc(100% - 166px ) !important
  @media(max-width:315px)
    width: calc(100% - 150px) !important
.dark .item
  color: white

.search-item
  margin-inline: 2px
  margin-bottom: 7px
  margin-top: 7px
  border: 1px solid grey
  border-radius: 50px
  color: grey
  height: 30px
.dark .search-item
  color: white

.btn-hover:hover
  opacity: 0.7

.truncate
  width: 100px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

.trend-icon
  height: 18px
.trend-icon-stroke
  stroke: grey
.dark .trend-icon-stroke
  stroke: white

.heading
  width: 23px
  height: 23px
  aspect-ratio: 1/1
  border: 1px solid
  border-radius: 50px
  padding-top: 1px

.ios .customize-btn
  height: 34px
  color: white
  border: 2px solid #7050B7
  border-radius: 20px
  margin-inline: 8px
  margin-bottom: -2px
  background: #7050B7
  font-weight: bold


.customize-btn
  height: 34px
  color: white
  border: 2px solid #7050B7
  border-radius: 20px
  margin-inline: 8px
  margin-bottom: -2px
  background: #7050B7
  font-weight: bold


.scroll-area
  max-height: 130px
  overflow-y: auto

.search-input
  height: 24px
  width: 100%
  .icon
    z-index: 111
  .c-input
    height: 28px
    background: transparent !important
    ::v-deep
      input
        background: #FFF
        padding: 0 5px 0 20px !important

.opened
  max-height: 1000px !important
  transition: ease-in-out 0.3s max-height

.closed
  max-height: 0px
  overflow-y: hidden
  transition: ease-in-out 0.3s max-height

::-webkit-scrollbar
  -webkit-appearance: none
  width: 7px !important

::-webkit-scrollbar-thumb
  border-radius: 4px
  background-color: rgba(0,0,0,.5)
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5)
</style>
