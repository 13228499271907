<template>
  <ion-segment
    mode="md"
    class="tabs compact no-shadow tabs-segment d-flex"
    :value="activeIndex"
    @ion-change="segmentChanged($event)"
  >
    <div
      v-for="(tab, index) of tabs"
      :key="index"
      class="new-badge d-flex justify-content-center position-relative"
      :class="{ 'no-char-user disabledhq': !userHasCharacters && tab.value !== 'social' }"
    >
      <div v-if="tab.value === 'quizzes' && quizNotificationCount > 0" class="quiz-notifs no-select">
        <ion-badge class="no-select">{{ quizNotificationCount }} new</ion-badge>
      </div>
      <!-- <i v-if="!userHasCharacters && tab.value !== 'social'" class="ti-lock disabled-tab-icon" @click="openPopup" /> -->
      <ion-segment-button
        :value="index"
        @click.prevent.stop="openPopup(tab.value, $event)"
        class="mr-1"
        :class="{
          'shade-new':
            activeIndex === eventsIndex && tab.value === 'events' && disabledForNewUser && !userHasCharacters,
        }"
      >
        <div class="d-flex align-items-center">
          <div
            class=""
            :class="{
              'mr-2': !isMobileSmallScreen,
              label: (!userHasCharacters && tab.value === 'social') || userHasCharacters,
              'quest-disabled': !userHasCharacters && tab.value !== 'social',
            }"
          >
            <ion-label class="quest-name no-select"
              >{{ tab.name }}
              <template v-if="isMobileSmallScreen"
                >{{ get(tabCounts, tab.value, 0) ? `(${get(tabCounts, tab.value, 0)})` : '' }}
                <i
                  v-if="!userHasCharacters && tab.value !== 'social'"
                  class="ti-lock disabled-side-icon"
                  @click="openPopup(tab.value, $event)"
              /></template>
            </ion-label>
          </div>
          <div>
            <div v-if="!isMobileSmallScreen" class="no-select" :class="userHasCharacters ? 'avv' : 'avv-disabled'">
              {{ !!get(tabCounts, tab.value, 0) ? `${get(tabCounts, tab.value, 0)} available` : '' }}
              <i
                v-if="!userHasCharacters && tab.value !== 'social'"
                class="ti-lock disabled-side-icon"
                @click="openPopup(tab.value, $event)"
              />
            </div>

            <!-- <i
              v-if="
                !userHasCharacters && tab.value !== 'social' && !isMobileSmallScreen && !get(tabCounts, tab.value, 0)
              "
              class="ti-lock disabled-side-icon"
              @click="openPopup(tab.value, $event)"
            /> -->
          </div>
        </div>
      </ion-segment-button>
      <ion-badge v-if="newCounts[tab.value] > 0" color="danger"
        >{{ newCounts[tab.value] >= 99 ? '99+' : newCounts[tab.value] }} new</ion-badge
      >
    </div>
  </ion-segment>
</template>

<script lang="ts" setup>
import { Tab } from '@/shared/types/static-types';
import { popovers } from '@/shared/native';
import { authStore } from '@/shared/pinia-store/auth';
import CharacterCreationPopover from '@/shared/components/popovers/character-creation-popover.vue';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
const { userHasCharacters } = authStore();
const { disabledForNewUser } = onboardingStore();

const props = defineProps({
  tabs: {
    type: Array<Tab>,
    default: [] as Tab[],
  },
  activeIndex: {
    type: Number,
    default: 0,
  },
  totalQuests: {
    type: Number,
    default: 0,
  },
  totalRaffles: {
    type: Number,
    default: 0,
  },
  newCounts: {
    type: Object,
    default: () => {},
  },
  quizzesCount: {
    type: Number,
    default: 0,
  },
  quizNotificationCount: {
    type: Number,
    default: 0,
  },
});

const prevTab = ref('events');

const tabs = toRef(props, 'tabs');
const newCounts = toRef(props, 'newCounts');
const totalQuests = toRef(props, 'totalQuests');
const totalRaffles = toRef(props, 'totalRaffles');
const activeIndex = toRef(props, 'activeIndex');
const quizzesCount = toRef(props, 'quizzesCount');

const eventsIndex = computed(() =>
  indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === 'events')
  )
);

const emits = defineEmits(['update:activeIndex', 'tab-changed', 'newFlag']);

const openPopup = async (tab: any, ev: CustomEvent) => {
  if (tab === 'social') return;
  if (!userHasCharacters.value) {
    await popovers.open(ev, CharacterCreationPopover);
  }
};
const segmentChanged = ($event: CustomEvent) => {
  const tabIndex = $event.detail.value as number;
  if (tabIndex !== undefined) {
    const tab = tabs.value[tabIndex];

    if (tab.value !== prevTab.value) {
      if (tab.value === 'raffles') {
        newCounts.value.events = 0;
        emits('newFlag', { events: false });
      } else {
        newCounts.value.raffles = 0;
        emits('newFlag', { raffles: false });
      }
    }
    prevTab.value = tab.value;

    emits('update:activeIndex', tabIndex);
    emits('tab-changed', tab);
  }
};
const isMobileSmallScreen = computed(() => {
  const { width } = useWindowSize();
  return width.value <= 550;
});

const tabCounts = computed(() => ({
  events: totalQuests.value,
  raffles: totalRaffles.value,
  quizzes: quizzesCount.value,
}));
</script>

<style scoped lang="sass">
.disabled-tab-icon
  font-size: 11px !important
  position: absolute
  top: -5px
  color: #c6c8d3
  z-index: 1000
  top: 3px
  right: calc(50% - 60px)
  @media(max-width: 1020px)
    right: calc(50% - 40px) !important
    top: 2px
    font-size: 10px !important !important
  @media(max-width: 1000px)
    right:  calc(50% - 40px) !important
    top: 3px
    font-size: 9px !important
.disabled-side-icon
  font-size: 11px !important
  position: absolute
  top: 3px
  color: #c6c8d3
  @media(max-width: 550px)
    right: 0px !important
.dark .disabledhq
  opacity: 0.5
.disabledhq
  opacity: 0.8
.no-char-user .segment-button-checked
  --background-checked: white
  --indicator-color: transparent !important
  background-color: var(--background-checked) !important
  border: 1px solid #C6C8D6 !important
  box-shadow: none !important
.dark .new-badge .segment-button-checked
  background: #17074C !important
  box-shadow: 0px 1px 5px 1px rgba(189, 189, 189, 0.5019607843) !important

.shade-new
  background: rgba(209, 171, 245, 0.8) !important
  pointer-events: none
  border-bottom: 0 !important
.dark .shade-new
  background: rgba(209, 171, 245, 0.8) !important
.quiz-notifs
  position: absolute
  display: flex
  align-items: center
  justify-content: center

.quest-disabled
  ion-label
    font-weight: 400 !important
    color: #C6C8D6 !important
.label
  margin-top: -2px
  ion-label
    font-weight: 400 !important
    color: rgba(103, 28, 164, 1) !important
.segment-button-checked
  --background-checked: white
  --indicator-color: transparent !important
  border-bottom: 0px !important
  box-shadow:1px 1px 1px 1px #bdbdbd80
  background-color: var(--background-checked) !important
  .label
    margin-top: -2px
    ion-label
      font-weight: bold !important
  .quest-disabled
    ion-label
      font-weight: bold !important
      color: #C6C8D6 !important
  .avv-disabled
    color: #C6C8D6 !important
.dark .segment-button-checked
  .quest-disabled
    ion-label
      color: #fff !important
  .avv-disabled
    color: #fff !important


.avv-disabled
  font-size: 10px !important
  color: #C6C8D6 !important
.avv
  font-size: 10px !important
  color: rgba(103, 28, 164, 1) !important
.quest-name
  :deep
    user-select: none !important
  @media(min-width: 551px)
    font-size: 18px



ion-segment
  margin-bottom: -1px

.tabs
  //margin-top: -10px
  height: 51px !important
  padding: 9px
  background: transparent
  *
    font-size: 14px

ion-segment-button
  min-width: unset !important
  --indicator-color: transparent
  --background-checked: white
  border-radius: 7px
  background: transparent
  --background: transparent
  //border-bottom-left-radius:0 !important
  //border-bottom-right-radius:0 !important
  min-height: 40px !important
  max-height: 40px !important
  height: 40px !important
  //color: #5d5c5c !important
  width: inherit

  //border: 1.5px solid rgba(103, 28, 164, 1) !important
  @media(max-width:900px)
    margin-right: 0px
    margin-left: 0px

  @media(max-width:390px)
    --padding-start: 5px
    --padding-end: 5px
    *
      font-size: 12px !important

.new-badge
  position: relative
  width: 100%
  ion-badge
    position: absolute
    z-index: 1
    top: -10px
    color: white
    border-radius: 10px
    padding: 4px
    background: #7050B7 !important
    font-size: 10px
</style>
