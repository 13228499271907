<template>
  <div class="user-img-section">
    <img loading="lazy" class="user-img" v-user-image :src="compliment.commented_as.profile_picture_url" />
    {{ compliment.commented_as.username }} said
  </div>
  <div class="d-flex align-items-center mb-2">
    <div class="d-flex justify-content-center mr-2 align-items-baseline">
      <div
        class="px-1 py-2 vote-section"
        @click="actionClicked"
        :class="{ 'vote-color-changed': !isEmpty(userReaction) }"
      >
        <i class="ti-arrow-up" />
        <div class="vote-text">VOTE</div>
      </div>
    </div>
    <div class="compliment-vote-card px-2 py-2" v-if="compliment.text" v-html="compliment.text" />
  </div>
  <div class="d-flex justify-content-end align-items-center">
    About:
    <img loading="lazy" v-character-image class="character-img mx-1" />
    <router-link :to="{ name: 'character-profile-new', params: { slug: compliment.commented_object.slug } }">
      {{ truncateText(compliment.commented_object.info.name, 25) }}
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { mainStore } from '@/shared/pinia-store/main';
import { complimentVoteStore } from '@/shared/pinia-store/complimentVote';
import { truncateText } from '@/shared/utils/string';

const props = defineProps({
  compliment: {
    type: Object,
    default: {},
  },
});
const { dark } = mainStore();
const compliment = toRef(props, 'compliment');

const { react } = complimentVoteStore();
const userReaction = computed(() => {
  return compliment?.value?.user_reaction;
});

const reactionChanged = async (reaction: string) => {
  await react(reaction, compliment.value);
};
const actionClicked = () => {
  reactionChanged('lov');
};

const colorChanged = computed(() => {
  return !isEmpty(userReaction.value) ? '#00b4c5 ' : '#c6c8d6';
});

const voteCardColor = computed(() => {
  return dark.value ? '#010123' : '#fff';
});
</script>

<style lang="sass" scoped>
.vote-color-changed
  color: #00a8b8 !important
.character-img
  width: 25px
  height: 25px
  border-radius: 18px
.vote-text
  font-size: 12px
.vote-section
  border: 3px solid v-bind(colorChanged)
  border-radius: 9px
  cursor: pointer
  color: v-bind(colorChanged)
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
.user-img-section
  padding-bottom: 8px
  padding-left: 58px
.user-img
  width: 25px
  height: 25px

.compliment-vote-card
  background: v-bind(voteCardColor)
  border-radius: 12px
  position: relative
  width: 100%
  z-index: 999

  &:before
    content: ""
    position: absolute
    border-style: solid
    border-width: 12px 11px 15px 11px
    border-color: transparent transparent v-bind(voteCardColor) transparent
    left: 10px
    top: -23px
</style>
