<template>
  <div class="raffles w-100 position-relative" :class="{ disabled: !userHasCharacters }">
    <div class="d-flex raffles-list w-100">
      <scroller
        :class="{ 'mx-2': loading }"
        ref="scrollerRef"
        :scroll-size="300"
        :threshold="20"
        class="w-100"
        :no-gradient-background="true"
        @resize="recalcScroll"
      >
        <ion-skeleton-text v-if="loading" :animated="true" class="raffle-loader" />
        <div v-else class="raffles-card d-flex align-items-center">
          <div class="d-flex flex-column">
            <ion-button class="see-all-text">
              <router-link v-if="seeAllLink" :to="seeAllLink" class="text-white">
                See all <span v-if="totalCount">{{ totalCount }}</span> ongoing raffles
              </router-link>
            </ion-button>
            <ion-button class="create-raffle-text" color="transparent">
              <router-link :to="'/raffles/create'" style="color: #7050b7">Create Your Raffle </router-link>
            </ion-button>
          </div>
          <div class="no-data d-flex justify-content-center align-items-center" v-if="isEmpty(raffles) && !loading">
            No Active Raffles!
          </div>
          <template v-for="(raffle, index) in raffles" :key="index">
            <HorizontalRaffles :raffle="raffle" />
          </template>
        </div>
      </scroller>
    </div>
  </div>
</template>

<script lang="ts" setup>
import HorizontalRaffles from './HorizontalRafflesCard.vue';
import { authStore } from '@/shared/pinia-store/auth';
const props = defineProps({
  raffles: { type: Array, default: () => [] },
  seeAllLink: { type: Object, default: () => {} },
  totalCount: { type: Number, default: 0 },
  raffleEntries: { default: () => [] },
  loading: { type: Boolean, default: false },
});

const raffles = toRef(props, 'raffles');
const scrollerRef: Ref = ref(null);
const { userHasCharacters } = authStore();
const recalcScroll = () => {
  setTimeout(() => {
    try {
      if (scrollerRef.value) (scrollerRef.value as any).calculateScroll();
    } catch (e) {}
  }, 150);
};
watch(
  raffles,
  () => {
    recalcScroll();
  },
  { deep: true }
);
</script>
<style lang="sass" scoped>
.raffles-card
  gap: 5px
  margin-top: 1rem
  margin-bottom: 0.5rem
.raffle-loader
  width: 100%
  height: 190px
.title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
.finish-msg
  font-size: 18px
  font-weight: bold
.raffles
  overflow-x: auto
.raffles-list
  overflow-x: auto
.raffles-list::-webkit-scrollbar
  display: none
.raffles-list
  -ms-overflow-style: none
  scrollbar-width: none
.visual-card
  overflow: unset !important
  width: 200px !important
.actions
  position: absolute
  bottom: 0
.visual-text
  font-size: 14px
  color: #214163
.dark
  .visual-text
    color: white
.see-all-text
  font-size: 16px
  width: 104px
  height: 116px
  white-space: normal !important
  text-align: left
  border-radius:10px
  background: #7050B7 !important
  --background: #7050B7 !important
  color: white
  text-decoration: underline
.create-raffle-text
  font-size: 12px
  font-weight: bolder
  white-space: break-spaces !important
  text-align: left
  width: 104px
  height: 55px
  border-radius: 10px
  border: 2px solid #7050B7
  color: #7050B7 !important
  text-decoration: underline

.dark .see-all-text
  color: #ddd !important
</style>
