<template>
  <div>
    <CharacterCard
      v-if="
        ['created_character', 'updated_char_text', 'updated_char_fields', 'updated_char_profile_img'].includes(
          feed.action
        )
      "
      :character="feed.entity"
      :feed="feed"
      :char-comments-count="charCommentsCount"
      :reacted-chars="reactedChars"
      :lazyUserReaction="lazyUserReaction"
      @updated="emits('updated')"
      @delete="deleteCurrGiftBox"
    />
    <story-world-card
      v-else-if="
        ['created_story_world', 'updated_story_world_text', 'updated_world_profile_img'].includes(feed.action) &&
        feed.entity.type === 'story'
      "
      :world="feed.entity"
      :feed="feed"
      :vertical="!isMobSmallScreen"
      :reacted-story="reactedWorld"
      :lazyUserReaction="lazyUserReaction"
      @updated="emits('updated')"
      @delete="deleteCurrGiftBox"
    />

    <social-space-card
      v-else-if="
        ['created_social_space_world', 'updated_social_world_text', 'updated_world_profile_img'].includes(feed.action)
      "
      :social-space="feed.entity"
      :feed="feed"
      :member-count="memberCount"
      :room-count="roomCount"
      :lazyUserReaction="lazyUserReaction"
      :for-feed="!isMobSmallScreen"
      :reacted-spaces="reactedSpaces"
      @updated="emits('updated')"
      @delete="deleteCurrGiftBox"
    />
    <market-place-card
      v-else-if="['created_marketplacelisting'].includes(feed.action)"
      :listing="feed.entity"
      :is-follow="true"
    />
    <post-card
      v-else-if="['created_blab'].includes(feed.action)"
      :lazyUserReaction="lazyUserReaction"
      :post="feed.entity"
      :containerHeight="get(feed, 'height')"
      :reacted-posts="reactedBlab"
      @updated="emits('updated')"
      @delete="deleteCurrGiftBox"
    />
    <FanArtCard
      v-if="['fanart_submitted', 'fanart_featured'].includes(feed.action)"
      :entity="feed.entity"
      :feed="feed"
      :containerHeight="get(feed, 'height')"
      @updated="emits('updated')"
      @delete="deleteCurrGiftBox"
    />
  </div>
</template>

<script lang="ts" setup>
import CharacterCard from '@/shared/components/storage/CharacterCard.vue';
import StoryWorldCard from '@/shared/components/storage/StoryWorldCard.vue';
import SocialSpaceCard from '@/shared/components/storage/SocialSpaceCard.vue';
import MarketPlaceCard from '@/shared/components/storage/MarketplaceCard.vue';
import FanArtCard from '@/shared/components/storage/FanArtCard.vue';
import PostCard from '@/shared/components/Posts/PostCard.vue';

const props = defineProps({
  feed: {
    type: Object,
    default: () => ({}),
  },
  lazyUserReaction: {
    type: Boolean,
    default: false,
  },
  charCommentsCount: {
    type: Number,
    default: 0,
  },
  memberCount: {
    type: Number,
    default: 0,
  },
  roomCount: {
    type: Number,
    default: 0,
  },
  reactedChars: {
    type: Object,
    default: null,
  },
  reactedSpaces: {
    type: Object,
    default: null,
  },
  reactedWorld: {
    type: Object,
    default: null,
  },
  reactedBlab: {
    type: Object,
    default: null,
  },
});

const feed = toRef(props, 'feed');
const emits = defineEmits(['updated', 'delete']);
const windowWidth = computed(() => {
  const { width } = useWindowSize();
  return width.value;
});

const isMobSmallScreen = computed(() => {
  return windowWidth.value < 380;
});
const deleteCurrGiftBox = (id: any) => {
  emits('delete', id);
};
</script>

<style lang="sass" scoped></style>
