<template>
  <ion-modal
    class="modal-big"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <ion-header translucent>
      <ion-toolbar class="toolbar">
        <div class="px-3 py-3">
          <div class="d-flex justify-content-between align-items-center">
            <div></div>
            <div>
              <div class="title">Compliment this Character!</div>
            </div>
            <div>
              <ion-buttons slot="end">
                <ion-button @click="closeModal">
                  <i class="ti-close" />
                </ion-button>
              </ion-buttons>
            </div>
          </div>
          <ion-skeleton-text v-if="isCbLoader" :animated="true" class="bar-loader" />
          <div class="d-flex total-char-section mt-1" v-else>
            <div class="d-flex align-items-center character-text-section">
              Characters
              {{ complimentCount }}/{{ totalInSet }}
            </div>
            <div class="px-1 py-1 mx-1 character-bar">
              <div class="d-flex" style="gap: 2px">
                <div
                  v-for="(character, index) in totalInSet"
                  :key="index"
                  class="selected-char-bar"
                  :class="{
                    'bar-color-changed': index < complimentCount,
                  }"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="modal-inner-content" :style="{ background: bg }">
      <ChLoading size="lg" v-if="loading" class="spinner mx-auto" />
      <div class="px-3">
        <character-profile :char-slug="characterSlug" :is-compliment-bomb="true" />
      </div>
    </div>
    <ion-footer translucent>
      <ion-toolbar class="toolbar" style="--min-height: 76px">
        <div class="d-flex px-3 mt-3">
          <div class="w-100 mr-3">
            <ion-textarea
              v-model="text"
              rows="3"
              class="c-textarea mb-3"
              placeholder="Share something fantastic that will make somebody's day."
              required
            />
          </div>
          <div class="d-flex flex-column w-50 align-items-center mx-auto">
            <ion-button class="compliment-button" :disabled="!isFormComplete || loader" @click="action">
              <ChLoading size="sm" v-if="loader" />
              <div v-else>Compliment</div>
            </ion-button>
            <div v-if="skipLoading" class="skip-loader">
              <ion-skeleton-text animated />
            </div>
            <div v-else class="skip clickable-item-hov" @click="skipChars">skip to next character</div>
          </div>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-modal>
</template>

<script lang="ts" setup>
import characterProfile from '@/shared/pages/character-profile-new/index.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { createComment } from '@/shared/actions/comments';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';

const { currentTemplate } = characterStylizeStore();
const document = useDocument();

const bg = computed(() => `${get(currentTemplate.value, 'data.theme.backgrounds.page')} !important`);

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
    required: true,
  },
  character: {
    type: Object,
    default: {},
  },
  cbDailyLimit: {
    type: Object,
    default: {},
  },
  complimentCharacters: {
    type: Array,
    default: [],
  },
  isCbLoader: {
    type: Boolean,
    default: false,
  },
});

const cbDailyLimit = toRef(props, 'cbDailyLimit');
const complimentCharacters: any = toRef(props, 'complimentCharacters');
const emits = defineEmits(['close', 'fetch', 'open', 'mission']);
const text = ref('');
const isOpen = toRef(props, 'isOpen');
const character: any = toRef(props, 'character');
const currentIndex: any = ref(null);
const loading = ref(false);
const skipLoading = ref(false);
const apiCallsCompleted = ref(false);
const prevIndex = ref(0);
const loader = ref(false);
const {
  user: loggedInUser,
  updateTempAvailableCompliment,
  previousAvailableCompliment,
  complimentCount,
  updateComplimentCount,
  totalComplimentedCount,
  updateTotalComplimentedCount,
} = authStore();

const dismissModal = (val: any) => {
  showCrisp();
  emits('close');
  prevIndex.value = 0;
  text.value = '';
  if (get(val, 'detail.role') === 'backdrop') {
    handleFetch();
  }
};

const closeModal = () => {
  dismissModal('');
  handleFetch();
};
watch(
  cbDailyLimit,
  (value: any) => {
    if (isOpen.value && value) {
      if (isNull(previousAvailableCompliment.value)) {
        updateTempAvailableCompliment(cbDailyLimit.value.available_compliments);
      }

      if (isNull(totalComplimentedCount.value)) {
        updateTotalComplimentedCount(cbDailyLimit.value.done_in_time);
      }
    }
  },
  { immediate: true }
);
watch(isOpen, async () => {
  if (isOpen.value && !isEmpty(complimentCharacters.value)) {
    document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'none';
    document.value.getElementsByTagName('html')[0].style.touchAction = 'none';

    (window as any).$crisp?.push(['do', 'chat:hide']);
    const el = document.value?.querySelector('.crisp-client') as any;
    if (el) el.style.display = 'none';

    prevIndex.value = findIndex(complimentCharacters.value, (char: any) => char.id === character.value.id);
    currentIndex.value = prevIndex.value;
  }
});

const totalInSet = computed(() => {
  const tempavailableComp = isNull(previousAvailableCompliment.value)
    ? cbDailyLimit.value.available_compliments
    : previousAvailableCompliment.value;
  return Math.min(tempavailableComp, cbDailyLimit.value.default_limit - totalComplimentedCount.value);
});

const showCrisp = () => {
  document.value.getElementsByTagName('html')[0].style.overscrollBehavior = 'auto';
  document.value.getElementsByTagName('html')[0].style.touchAction = 'auto';

  (window as any).$crisp?.push(['do', 'chat:show']);
  const el = document.value?.querySelector('.crisp-client') as any;
  if (el) el.style.display = 'block';
};

const currentCharacter = computed(() => {
  const lastIndex = complimentCharacters.value.length - 1;
  return currentIndex.value === null
    ? character.value
    : currentIndex.value === lastIndex
    ? complimentCharacters.value[lastIndex]
    : complimentCharacters.value[currentIndex.value];
});

const isFormComplete = computed(() => {
  return !isEmpty(text.value);
});

const characterSlug = computed(() => {
  if (isEmpty(currentCharacter.value)) return;
  return get(currentCharacter.value, 'slug');
});

const resetIndex = async () => {
  currentIndex.value = 0;
  isCurrEqualToPrev();
};

const isCurrEqualToPrev = () => {
  const prevIn = prevIndex.value;

  if (currentIndex.value === prevIn) {
    emits('open', complimentCount.value);
  }
};

const skipChars = async () => {
  skipLoading.value = true;
  if (currentIndex.value >= complimentCharacters.value.length - 1) {
    await resetIndex();
  } else {
    updateIndex();
  }
  skipLoading.value = false;
};
const isLimitReached = computed(() => {
  return complimentCount.value >= totalInSet.value;
});
watch(apiCallsCompleted, () => {
  if (isOpen.value && apiCallsCompleted.value) {
    if (isLimitReached.value) {
      emits('mission');
      apiCallsCompleted.value = false;
    } else {
      updateCount();
      apiCallsCompleted.value = false;
    }
  }
});

const updateIndex = async () => {
  currentIndex.value = currentIndex.value + 1;
  isCurrEqualToPrev();
};

const resetText = () => {
  text.value = '';
};

const handleFetch = async () => {
  emits('fetch');
};

const updateCount = async () => {
  let tempCount = complimentCount.value + 1;

  if (isLimitReached.value) return;

  updateComplimentCount(tempCount);
};

const postComment = async () => {
  await createComment({
    text: text.value,
    commenter_id: loggedInUser.value.id,
    commenter_type: 'user',
    commented_on_type: 'character',
    commented_on_object_id: currentCharacter.value.id,
    compliment_bomb: currentCharacter.value.compliment_bombed_id,
  });

  resetText();
  apiCallsCompleted.value = true;
};

const action = async () => {
  loader.value = true;
  if (complimentCount.value <= cbDailyLimit.value.default_limit) {
    if (currentIndex.value >= complimentCharacters.value.length - 1) {
      await postComment();
      resetIndex();
    } else {
      await postComment();
      updateIndex();
    }
  }
  loader.value = false;
};
</script>
<style lang="sass" scoped>
.bar-loader
  width: 100%
  height: 17px

.skip-loader
  width: 100%
  height: 15px
.skip
  font-weight: bold
  text-decoration: underline
.compliment-button
  width: 100%
  height: 40px
.selected-char-bar
  background: #e6e6e6
  width: 100%
  border-radius: 20px
  padding-bottom: 0.4rem
  padding-top: 0.4rem
.bar-color-changed
  background: #00b4c5 !important
.character-bar
  border: 2px solid
  border-radius: 20px
  height: 24px
  width: 100%
.total-char-section
  white-space: nowrap
  color: #00b4c5
.character-text-section
  font-weight: bold
  @media(max-width:360px)
    font-size: 14px
.dark .toolbar
  --background: #17074c !important

.modal-inner-content
  background: var(--ion-background-color, #fff)
  max-height: 60vh
  overflow: auto

.modal-big
  --width: 600px
  --height: auto
  @media(max-width: 576px)
    --width: 100%
.close-button
  width: 50px
.title
  color: #214163
  font-size: 20px
  font-weight: bold
  text-align: center
  align-items: center
.char-compliment-bombed
  border-radius: 10px
  border: 2px solid #cfcaca
</style>
