<template>
  <div class="d-flex">
    <div class="d-flex align-items-center justify-content-center mr-2">
      <inline-svg class="target-sticker" src="/compliment-target-sticker.svg" />
    </div>
    <div clas="d-flex flex-column ">
      <div class="title">TARGET ACQUIRED!</div>
      <div class="target-text" v-for="(target, index) in complimentTarget" :key="index">
        <ion-badge class="target-badge">{{ index + 1 }}</ion-badge>
        <span class="mx-1 text-black">{{ target }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { complimentTargetBomb } from '@/shared/statics/constants';
const complimentTarget = complimentTargetBomb;
</script>

<style lang="sass" scoped>
.target-badge
  background: var(--ion-color-primary)
  font-weight: bold
  display: flex
  height: 19px
  width: 19px
  align-items: center
  justify-content: center
  min-width: 19px
  max-width: 19px
  font-size: 16px
  align-self: baseline
.target-sticker
  width: 107px
  height: 107px
.title
  color: #214163
  font-weight: bolder
  font-size: 20px
.target-text
  display: flex
  align-items: center
  padding-top: 7px
  padding-bottom: 7px
</style>
